import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import {
  selectComparisonBoardProductTemplate,
  selectComparisonBoardProducts,
} from '../../../../state/comparisonBoard/selectors'
import ScoreCard from './ScoreCard'
import { modelChangeLevels } from '../../../../services/comparisonBoard'
import { hasChildrenCriterionModelChange } from '../../../../services/modelChange'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

function ItemRow({
  nodeDefinition,
  modelChangeItem,
  productsData,
  modelChangeData,
  template,
  referenceProduct,
  modelChangeItemIndex,
}) {
  const productsDataForLevel = productsData.filter(_p => _p.id !== referenceProduct?.id)

  // get score levels
  const scoreLevels = useMemo(() => {
    return modelChangeLevels(nodeDefinition.id, productsDataForLevel, modelChangeItem.id)
  }, [nodeDefinition, productsData])

  // cells
  const memoizedScoreCells = useMemo(() => {
    return _.map(productsData, (product, index) => {
      const node = _.find(product.nodes, _n => _n.node_definition_id === nodeDefinition.id)
      const referenceNode = _.find(referenceProduct?.nodes, _n => _n.node_definition_id === nodeDefinition.id)

      const hasScoreLevel = scoreLevels?.[product.product_id] !== undefined
      const scoreLevelIndex = hasScoreLevel ? scoreLevels[product.product_id] : null

      return (
        <td
          key={uuidv4()}
          className={classNames(
            'comparison-board-table__value-cell',
            'comparison-board-table__value-cell--model-change',
            `comparison-board-table__value-cell--${index}`,
            {
              'comparison-board-table__value-cell--last': productsData.length === index + 1,
              [`comparison-board-table-best-worst--${scoreLevelIndex}`]: hasScoreLevel,
              [`comparison-board-table__value-cell--model-change-item-${(modelChangeItemIndex % 4) + 1}`]:
                modelChangeItemIndex !== undefined || modelChangeItemIndex !== null,
            }
          )}
        >
          <ScoreCard node={node} referenceNode={referenceNode} modelChangeItem={modelChangeItem} />
        </td>
      )
    })
  }, [productsData])

  // check if family has criteria in model change data
  const hasModelChange = useMemo(() => {
    return hasChildrenCriterionModelChange(template, nodeDefinition, modelChangeData, modelChangeItem?.id)
  }, [modelChangeData, template])

  return (
    <>
      {hasModelChange && (
        <tr className="comparison-board-table__body-row">
          <td className="comparison-board-table__header-cell comparison-board-table__header-cell--model-change-item">
            <div className="comparison-board-table-header-cell__name">{modelChangeItem.value}</div>
          </td>
          {referenceProduct && memoizedScoreCells}
        </tr>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    productsData: selectComparisonBoardProducts(state),
    modelChangeData: state.renaultProjectMode.comparisonBoard.modelChangeData,
    template: selectComparisonBoardProductTemplate(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ItemRow)
