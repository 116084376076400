import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import '../../styles/comparison-board.scss'
import Layout from 'layout'
import NotFoundPage from '../../../../pages/404'
import * as renaultProjectModeActionCreators from '../../state/actions'
import { isRenaultProjectModeEnabled } from '../../../../../va-corejs-v3/utils'
import Table from '../components/ComparisonBoard/Table'
import NodeModal from '../components/ComparisonBoard/NodeModal'
import { selectNodeDefinitionForModal } from '../../state/comparisonBoard/selectors'
import { LoadingBar } from '../../../../components/loading_bar'

const classNames = require('classnames')

function ComparisonBoardPage({ systemLoaded, location, initComparisonBoard, nodeDefinitionForModal, authentication }) {
  const [found, setFound] = useState(false)
  const [loading, setLoading] = useState(true)
  const hasPermission = authentication?.user?.parsedPermissions?.renaultProjectMode

  useEffect(() => {
    const init = async () => {
      const params = new URLSearchParams(location.search)

      if (params.has('project_id') && params.has('configuration_category_id')) {
        try {
          await initComparisonBoard(params.get('project_id'), params.get('configuration_category_id'))
          // await calcProductsCalculatedWeights()
          setFound(true)
        } catch (e) {
          console.log(e)
          setFound(false)
        }

        setLoading(false)
      }
    }

    if (systemLoaded) {
      init()
    }
  }, [systemLoaded])

  if (!isRenaultProjectModeEnabled() || !hasPermission) {
    return (
      <Layout>
        <NotFoundPage />
      </Layout>
    )
  }

  return (
    <Layout className="rpm-comparison-board-page">
      {!loading && !found && <NotFoundPage />}
      {loading && (
        <div className="comparison-board-page comparison-board-page--loading">
          <LoadingBar />
        </div>
      )}
      {!loading && found && (
        <div
          className={classNames('comparison-board-page', {
            'comparison-board-page--node-modal-open': !!nodeDefinitionForModal,
          })}
        >
          <Table />
          {nodeDefinitionForModal && <NodeModal />}
        </div>
      )}
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    systemLoaded: state.environment.systemLoaded,
    nodeDefinitionForModal: selectNodeDefinitionForModal(state),
    authentication: state.authentication,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ComparisonBoardPage)
