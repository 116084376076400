import React from 'react'
import { connect } from 'react-redux'
import { isNaN } from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import { toFixed1IfDecimal } from '../../../../../../../va-corejs-v3/utils'

function ScoreCard({ node, referenceNode, modelChangeItem }) {
  if (node.id === referenceNode.id) {
    return <div className="comparison-board-table__score-card comparison-board-table__score-card--model-change" />
  }

  const modelChangeScore = node?.model_change?.find(score => score.model_change_item_id === modelChangeItem.id)
  const modelChangeScoreValue = modelChangeScore?.score
  const referenceModelChangeScore = referenceNode?.model_change?.find(
    score => score.model_change_item_id === modelChangeItem.id
  )
  const referenceModelChangeScoreValue = referenceModelChangeScore?.score

  const score =
    node.id === referenceNode.id ? modelChangeScoreValue : modelChangeScoreValue - referenceModelChangeScoreValue

  return (
    <div className="comparison-board-table__score-card comparison-board-table__score-card--model-change">
      {!isNaN(modelChangeScoreValue) ? toFixed1IfDecimal(score) : ''}
    </div>
  )
}

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ScoreCard)
