import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import {
  selectComparisonBoardProductTemplate,
  selectComparisonBoardProducts,
} from '../../../../state/comparisonBoard/selectors'
import UntaggedScoreCard from './UntaggedScoreCard'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

function UntaggedItemRow({ nodeDefinition, productsData, referenceProduct, modelChangeItems }) {
  const referenceNode = _.find(referenceProduct?.nodes, _n => _n.node_definition_id === nodeDefinition.id)

  const referenceAndScore = useMemo(() => {
    let referenceModelChangeScoresSum = 0

    if (!referenceNode) return referenceModelChangeScoresSum

    Object.values(modelChangeItems).forEach(modelChangeItem => {
      const referenceModelChangeScore = referenceNode?.model_change?.find(
        score => score.model_change_item_id === modelChangeItem.id
      )
      const referenceModelChangeScoreValue = referenceModelChangeScore?.score ?? 0
      referenceModelChangeScoresSum += referenceModelChangeScoreValue
    })

    return referenceNode.normalized_score - referenceModelChangeScoresSum
  }, [referenceNode?.id])

  // cells
  const memoizedScoreCells = useMemo(() => {
    return _.map(productsData, (product, index) => {
      const node = _.find(product.nodes, _n => _n.node_definition_id === nodeDefinition.id)

      return (
        <td
          key={uuidv4()}
          className={classNames(
            'comparison-board-table__value-cell',
            'comparison-board-table__value-cell--model-change',
            `comparison-board-table__value-cell--${index}`,
            {
              'comparison-board-table__value-cell--last': productsData.length === index + 1,
            }
          )}
        >
          <UntaggedScoreCard node={node} referenceNode={referenceNode} referenceAndScore={referenceAndScore} />
        </td>
      )
    })
  }, [productsData])

  return (
    <tr className="comparison-board-table__body-row">
      <td className="comparison-board-table__header-cell comparison-board-table__header-cell--model-change-item">
        <div className="comparison-board-table-header-cell__name">&</div>
      </td>
      {referenceProduct && memoizedScoreCells}
    </tr>
  )
}

const mapStateToProps = state => {
  return {
    productsData: selectComparisonBoardProducts(state),
    modelChangeItems: state.renaultProjectMode.comparisonBoard.modelChangeItems,
    template: selectComparisonBoardProductTemplate(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(UntaggedItemRow)
