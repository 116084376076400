import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import Header from './Header'
import ItemRow from './ItemRow'
import { selectIsExpandedModelChangeRow } from '../../../../state/comparisonBoard/selectors'
import UntaggedItemRow from './UntaggedItemRow'

function Row({ nodeDefinition, modelChangeItems, expanded, referenceProduct }) {
  return (
    <>
      <tr className="comparison-board-table__body-row">
        <td className="comparison-board-table__header-cell comparison-board-table__header-cell--model-change">
          <Header nodeDefinition={nodeDefinition} />
        </td>
      </tr>
      {expanded && modelChangeItems && Object.values(modelChangeItems).length > 0 && (
        <>
          {Object.values(modelChangeItems).map((modelChangeItem, index) => (
            <ItemRow
              key={`row-${nodeDefinition.id}-${modelChangeItem.id}`}
              nodeDefinition={nodeDefinition}
              modelChangeItem={modelChangeItem}
              referenceProduct={referenceProduct}
              modelChangeItemIndex={index}
            />
          ))}
          <UntaggedItemRow nodeDefinition={nodeDefinition} referenceProduct={referenceProduct} />
        </>
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    modelChangeItems: state.renaultProjectMode.comparisonBoard.modelChangeItems,
    expanded: selectIsExpandedModelChangeRow(state, ownProps.nodeDefinition.id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Row)
