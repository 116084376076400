import React, { useMemo } from 'react'
import { isNaN } from 'lodash'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import { toFixed1IfDecimal } from '../../../../../../../va-corejs-v3/utils'

function UntaggedScoreCard({ node, referenceNode, modelChangeItems, referenceAndScore }) {
  if (node.id === referenceNode.id) {
    return <div className="comparison-board-table__score-card comparison-board-table__score-card--model-change" />
  }

  const value = useMemo(() => {
    let modelChangeScoresSum = 0

    Object.values(modelChangeItems).forEach(modelChangeItem => {
      const modelChangeScore = node?.model_change?.find(score => score.model_change_item_id === modelChangeItem.id)
      const modelChangeScoreValue = modelChangeScore?.score ?? 0
      modelChangeScoresSum += modelChangeScoreValue
    })

    const andScore = node.normalized_score - modelChangeScoresSum

    return andScore - referenceAndScore
  }, [node?.id, referenceNode?.id])

  return (
    <div className="comparison-board-table__score-card comparison-board-table__score-card--model-change">
      {!isNaN(value) ? toFixed1IfDecimal(value) : ''}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    modelChangeItems: state.renaultProjectMode.comparisonBoard.modelChangeItems,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(UntaggedScoreCard)
